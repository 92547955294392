import { FormState } from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse, Submission } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import {
  mapSubmissionToPartialBookRequest,
  mapFormSubmission,
  mapDynamicParticipantsNumber,
} from '../../../../utils/mappers/form-submission.mapper';
import {
  DialogType,
  ReservedPaymentOptionIds,
  CartFlow,
  CartModalStatus,
} from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import {
  bookingsContactInfoSaveSuccess,
  bookingsUouBookFlowBookingFormClickNext,
  bookingsUouBookFlowBookingFormClickNextFailure,
  bookingsPaymentMethodSelectionNextClicked,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType } from '../../../../types/errors';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { FormStatus } from '../../../../types/form-state';
import {
  ContactDetails,
  CreateBookingResponse,
} from '@wix/ambassador-bookings-gateway/types';
import { Experiments } from '@wix/yoshi-flow-editor';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { CustomOption, DynamicPriceInfo } from '../../../../types/dynamicPrice';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { mapCartToBookingsLineItems } from '../../../../utils/mappers/cart.mapper';
import {
  AddToCartResponse,
  Cart,
  UpdateCartRequest,
} from '@wix/ambassador-cart/types';
import { FormApi } from '../../../../api/FormApi';

export type OnSubmit = (
  submission: SubmissionResponse,
  cartFlow?: CartFlow,
) => Promise<void>;

enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
  TEMPLATE,
}

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async (submissionResponse, cartFlow) => {
    const [state] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter, experiments } = actionFactoryParams.context;
    const { status } = state;
    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        switch (getCurrentFlow(state, wixSdkAdapter, experiments)) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              submissionResponse.submission,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.TEMPLATE:
            return handleTemplateFlow(actionFactoryParams);
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submissionResponse.submission,
              cartFlow,
            );
        }
      }
    }
  };
}

function getCurrentFlow(
  state: FormState,
  wixSdkAdapter: WixOOISDKAdapter,
  experiments: Experiments,
): FlowType {
  const { isBookingsOnEcom } = state;
  const isUoUTemplateModeModalEnabled = experiments.enabled(
    ExperimentsConsts.UoUTemplateModeModal,
  );
  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (
    state.selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan
  ) {
    return FlowType.CHOOSE_PLAN;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else if (
    wixSdkAdapter.isTemplateMode() &&
    isBookingsOnEcom &&
    isUoUTemplateModeModalEnabled
  ) {
    return FlowType.TEMPLATE;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

export async function handleChoosePlanFlow(
  { getControllerState, context: { wixSdkAdapter } }: CreateActionParams,
  submission: Submission,
) {
  const [state, setState] = getControllerState();
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  const { service, slotAvailability, editorContext } = state;
  setFieldsValuesInStorage(wixSdkAdapter, submission);
  if (editorContext.isDummy) {
    await wixSdkAdapter.navigateToPricingPlanPreview();
  } else {
    await purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
  }
}

export async function handleOwnerFlow({
  getControllerState,
  context: { t },
}: CreateActionParams) {
  const [, setState] = getControllerState();
  setState(
    getOwnerSubmitDialog({
      contentText: t('app.dialog.owner-submit.content'),
      confirmButtonText: t('app.dialog.owner-submit.confirm-button'),
    }),
  );
}
export async function handlePreviewFlow({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams) {
  const [state] = getControllerState();
  const { isBookingsOnEcom } = state;
  if (isBookingsOnEcom) {
    await wixSdkAdapter.openEcomCheckoutPreviewModal();
  } else {
    await wixSdkAdapter.navigateToBookingsWithSuffix();
  }
}

export async function handleTemplateFlow({
  context: { wixSdkAdapter },
}: CreateActionParams) {
  await wixSdkAdapter.openEcomCheckoutTemplateModal();
}

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: { biLogger, wixSdkAdapter, formApi, reportError, experiments },
  }: CreateActionParams,
  submission: Submission,
  cartFlow?: CartFlow,
) {
  const [state, setState] = getControllerState();
  const {
    pricingPlanDetails,
    memberships,
    service,
    businessInfo,
    couponInfo,
    slotAvailability,
    selectedPaymentOptionId,
    selectedPaymentType,
    isBookingsOnEcom,
    dynamicPriceInfo,
    paymentDetails,
    isCart,
  } = state;
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  setFieldsValuesInStorage(wixSdkAdapter, submission);

  if (isBookingsOnEcom) {
    const selectedMembership = memberships?.eligibleMemberships?.find(
      (membership) => membership?.id === selectedPaymentOptionId,
    );
    try {
      const {
        contactDetails,
        additionalFields,
        numberOfParticipants,
        sendSmsReminder,
      } = mapFormSubmission(submission, service, businessInfo);
      const {
        participantsChoices,
        totalParticipants,
      } = mapDynamicParticipantsNumber(dynamicPriceInfo?.selectedVariants);

      const {
        createCheckoutResponse,
        createBookingResponse,
        addToCurrentCartResponse,
      } = await formApi.checkoutBooking({
        service,
        slot: slotAvailability.slot!,
        contactDetails,
        additionalFields,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        selectedMembership,
        selectedPaymentType,
        ...(participantsChoices ? { participantsChoices } : {}),
        ...(totalParticipants ? { totalParticipants } : {}),
        ...(!dynamicPriceInfo?.serviceOptionsAndVariants
          ? { numberOfParticipants }
          : {}),
        isCart,
        ...(isCart && cartFlow === CartFlow.CHECKOUT
          ? { cartFlow: CartFlow.CHECKOUT }
          : {}),
        paymentDetails,
        onError: (error) => {
          errorHandlers.addError(error);
          biLogger?.report(
            bookingsUouBookFlowBookingFormClickNextFailure({
              errorReason: error,
            }),
          );
        },
      });

      if (
        isCart &&
        addToCurrentCartResponse &&
        !addToCurrentCartResponse?.cart
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      } else {
        if (
          createCheckoutResponse &&
          createBookingResponse &&
          !isSubmitValid(createCheckoutResponse, createBookingResponse)
        ) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        let dynamicPriceBIReportParams;
        if (dynamicPriceInfo?.serviceOptionsAndVariants) {
          dynamicPriceBIReportParams = getDynamicPriceBIReportParams(
            dynamicPriceInfo,
          );
        }
        biLogger?.report(
          bookingsUouBookFlowBookingFormClickNext({
            smsNotificationRequest: sendSmsReminder,
            bookingId: createBookingResponse?.booking?.id,
            ...(dynamicPriceBIReportParams ? dynamicPriceBIReportParams : {}),
          }),
        );
      }

      const isDayfulOverrideEcomContinueShoppingUrlEnabled = experiments.enabled(
        ExperimentsConsts.DayfulOverrideEcomContinueShoppingUrl,
      );

      let continueShoppingUrl = '';
      let isDayful: boolean;

      if (isDayfulOverrideEcomContinueShoppingUrlEnabled) {
        const queryParams = wixSdkAdapter.getUrlQueryParams();
        isDayful = queryParams.dayful === 'sch';
        if (isDayful || queryParams.origin) {
          continueShoppingUrl =
            queryParams.origin ||
            `${await wixSdkAdapter.getBookOnlineFullUrl()}?dayful=${
              queryParams.dayful
            }`;
        }
      }

      if (isCart) {
        const lineItems = addToCurrentCartResponse?.cart?.lineItems || [];
        setState({
          cartModal: {
            status: CartModalStatus.OPEN,
            lineItems,
          },
          status: FormStatus.IDLE,
        });
        if (lineItems.length === 1) {
          await updateCartWithMemberDetails({
            addToCurrentCartResponse,
            contactDetails,
            formApi,
          });
        }
        await wixSdkAdapter.reloadCart();
      } else {
        if (createCheckoutResponse && isOnlineFlow(createCheckoutResponse)) {
          return wixSdkAdapter.navigateToEcomCheckoutPage({
            checkoutId: createCheckoutResponse!.checkout!.id!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        } else {
          return wixSdkAdapter.navigateToEcomThankYouPage({
            orderId: createCheckoutResponse!.orderId!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        }
      }
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      reportError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsUouBookFlowBookingFormClickNextFailure({
          errorReason: error,
        }),
      );
    }
  } else {
    const selectedPlan = pricingPlanDetails?.plans?.find(
      (plan) => plan?.paidPlan?.orderId === selectedPaymentOptionId,
    );
    try {
      const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
        submission,
        service,
        businessInfo,
        slotAvailability?.slot?.timezone,
      );
      const bookingResponse = await formApi.book({
        service,
        formInfo,
        slotAvailability,
        selectedPlan,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        onError: (error) => {
          errorHandlers.addError(error);
          biLogger?.report(
            bookingsPaymentMethodSelectionNextClicked({
              errorMessage: JSON.stringify(error),
            }),
          );
        },
      });

      if (!bookingResponse?.booking) {
        setState({ status: FormStatus.IDLE });
        return;
      }

      biLogger?.report(
        bookingsContactInfoSaveSuccess({
          smsNotificationRequest: sendSmsReminder,
        }),
      );

      delete bookingResponse.booking?.formInfo?.additionalFields;
      delete bookingResponse.booking?.formInfo?.customFormFields;

      return wixSdkAdapter.navigateToBookingsCheckout(
        bookingResponse.booking! as any,
        widgetDefaults.pageName,
      );
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsPaymentMethodSelectionNextClicked({
          errorMessage: JSON.stringify(error),
        }),
      );
      reportError(error as BookErrorType);
    }
  }
}

async function updateCartWithMemberDetails({
  addToCurrentCartResponse,
  contactDetails,
  formApi,
}: {
  addToCurrentCartResponse?: AddToCartResponse;
  contactDetails: ContactDetails;
  formApi: FormApi;
}) {
  const cartLineItems = addToCurrentCartResponse?.cart?.lineItems || [];
  const bookingsLineItems = mapCartToBookingsLineItems(cartLineItems);
  const isFirstBookingItemOnCart = bookingsLineItems.length === 1;
  if (isFirstBookingItemOnCart) {
    const cartInfo: Cart = {
      id: addToCurrentCartResponse?.cart?.id,
      contactInfo: {
        address: {
          city: contactDetails?.fullAddress?.city || '',
          addressLine: contactDetails?.fullAddress?.addressLine || '',
        },
        contactDetails: {
          firstName: contactDetails?.firstName || '',
          lastName: contactDetails?.lastName || '',
        },
      },
    };
    const updateCartRequest: UpdateCartRequest = {
      cartInfo,
    };
    await formApi.updateCart(updateCartRequest);
  }
}

function isOnlineFlow(
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse {
  return (checkoutResponse as CreateCheckoutResponse)?.checkout !== undefined;
}

function getOwnerSubmitDialog({
  contentText,
  confirmButtonText,
}: {
  contentText: string;
  confirmButtonText: string;
}): Partial<FormState> {
  return {
    dialog: {
      type: DialogType.OwnerSubmit,
      props: {
        isOpen: true,
        contentText,
        confirmButtonText,
      },
    },
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { referral } = getQueryParams(wixSdkAdapter);

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone: slotAvailability.slot?.timezone,
      service: service.id,
    },
  });
}

export function getQueryParams(
  wixSdkAdapter: WixOOISDKAdapter,
): {
  [key: string]: string;
} {
  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );
  return { referral };
}
const isSubmitValid = (
  createCheckoutResponse: CreateCheckoutResponse | CreateOrderResponse,
  createBookingResponse: CreateBookingResponse,
) => {
  if (!createBookingResponse?.booking?.id) {
    return false;
  }

  if (isOnlineFlow(createCheckoutResponse)) {
    return createCheckoutResponse.checkout?.id !== undefined;
  }

  return createCheckoutResponse?.orderId !== undefined;
};

const getDynamicPriceBIReportParams = (
  dynamicPriceInfo: DynamicPriceInfo,
): {
  price_type?: ServiceOptionType;
  numOfOptions?: number;
  dynamic_price_participants_json?: string;
} => {
  const dynamicPriceType = dynamicPriceInfo?.selectedPreferences?.[0].type;
  const isCustomType = dynamicPriceType === ServiceOptionType.CUSTOM;
  const dynamic_price_participants_json = isCustomType
    ? JSON.stringify(
        dynamicPriceInfo.selectedVariants?.map(
          (selectedVariant: SelectedVariants) => {
            const option = dynamicPriceInfo?.customOptions?.find(
              (customOption: CustomOption) =>
                customOption.optionId ===
                selectedVariant.choices?.[0]?.optionId,
            )?.options?.[0];
            return (
              option && {
                label: option?.title,
                amount: option?.price,
                num_participants: selectedVariant.numberOfParticipants,
              }
            );
          },
        ),
      )
    : '';
  return {
    price_type: dynamicPriceType,
    numOfOptions:
      dynamicPriceInfo?.serviceOptionsAndVariants?.options?.values?.length,
    dynamic_price_participants_json,
  };
};
